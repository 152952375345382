// loaded as prioroty

.display-none{
    display: none !important;
}

.display-block{
    display:block !important;
}

.display-inline-block{
    display: inline-block !important;
}

.visibility-hidden{
    visibility: hidden !important;
}

.visibility-visible{
    visibility: visible !important;
}

.overflow-x-hidden{
    overflow-x: hidden !important;
}

.overflow-y-hidden{
    overflow-y: hidden !important;
}

.overflow-x-visible{
    overflow-x: visible !important;
}

.overflow-y-visible{
    overflow-y: visible !important;
}

.overflow-hidden{
    overflow: hidden;
}

.overflow-auto{
    overflow: auto;
}

.pointer-events-none{
    pointer-events: none;
}

.cursor-none{
    cursor: unset;
}

.cursor-grabbing{
    cursor: grabbing;
}
